@import '~/ui/assets/styles/colors';

.container {
  border-radius: 15px 15px 0 0;
  background-color: $color-white;
  padding-bottom: 15px;
  border: 1px solid $color-grey-6;
}

.tableTopHeader {
  margin-top: 0;
  padding: 5px 20px;
  width: 100%;
  background-color: $color-white-2;
  border-radius: 15px 15px 0 0;
}

.tableContainer {
  padding: 0 15px;
}
