@import '~/ui/assets/styles/colors.scss';

.formContainer {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.noteLength {
  font-size: 8px;
  margin-top: 10px;
  color: $color-grey-2;
}

.buttonWrapper {
  display: flex;
  margin-top: 15px;
  align-self: flex-end;
}

.spacer {
  width: 10px;
}

.btnSkip {
  margin-left: 10px !important;
}
