.battery {
  display: flex;
  align-items: center;
}

.batteryWrapper {
  position: relative;
  height: 12px;
  width: 22px;
  padding: 1px;
}

.square {
  position: absolute;
  top: 2px;
  right: -3px;
  width: 2px;
  height: 6px;
}

.indicator {
  height: 100%;
}

.title {
  padding-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
