@import '~/ui/assets/styles/colors';

.smallCard {
  display: inline-block;
}

.inner {
  display: flex;
  align-items: center;
  padding: 15px 30px 15px 20px;
  background-color: $color-white;
  border: 1px solid $color-grey-5;
  border-radius: 12px;
  box-shadow: 0 7px 24px -10px rgba(86, 116, 138, 0.3);
  cursor: pointer;
  transition-duration: 0.15s;

  &:hover {
    color: $color-white;
    background-color: $color-blue-1;
    box-shadow: 0 4px 14px rgba(13, 118, 177, 0.4);

    .blue,
    .yellow,
    .green {
      color: $color-white;
    }
  }
}

.icon {
  font-size: 32px !important;
}

.title {
  padding-left: 15px;
  font-size: 16px;
}

.blue {
  color: $color-blue-1;
}

.yellow {
  color: $color-yellow-1;
}

.green {
  color: $color-green-1;
}
