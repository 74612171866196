@import '~/ui/assets/styles/colors.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  background-color: $color-grey-5;
  border-radius: 5px;
}

.link {
  margin-left: 25px;
  text-decoration: none;
}
