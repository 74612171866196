@import '~/ui/assets/styles/colors';

.container {
  margin-top: 25px;
  border-radius: 15px 15px 0 0;
  background-color: $color-white;
  padding-bottom: 15px;
  border: 1px solid $color-grey-6;
}

.headerContainer {
  padding: 5px 20px;
  background-color: $color-white-2;
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formContainer {
  padding: 0 15px;
}

.divider {
  height: 25px;
  width: 1px;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}
