@import '~/ui/assets/styles/colors';

.iconButton {
  padding: 5px !important;
}

.icon {
  font-size: 20px !important;

  &:hover {
    color: $color-blue-1;
  }
}
