.table {
  thead th,
  tbody td {
    text-align: center;
  }
}

.table {
  thead th:nth-child(1),
  tbody td:nth-child(1) {
    text-align: left;
  }
}
