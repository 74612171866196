@import '~/ui/assets/styles/colors';

.selectControl {
  position: relative;
  width: 100%;
  text-align: left;
}

.label {
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 0 4px !important;
  font-size: 12px !important;
  background: $color-white;
  z-index: 1;
}
