@import '~/ui/assets/styles/colors';

.accessDenied {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 32px;
  color: $color-red-1;
}

.icon {
  font-size: 36px !important;
}

.title {
  padding-left: 10px;
}
