@import '~/ui/assets/styles/colors.scss';

.menuWrapper {
  position: relative;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.menu {
  position: absolute;
  top: 5px;
  right: 0;
  min-width: 100px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: $color-black;
  list-style-type: none;
  background-color: $color-white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  z-index: 1001;
}

.item {
  &:hover {
    background-color: $color-grey-3;
  }
}

.itemLink {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  color: $color-black;
  cursor: pointer;
}

.itemLink:visited {
  color: $color-black;
}
