.addProgram {
  padding-top: 15px;
}

.title {
  padding-bottom: 20px;
  font-size: 18px;
}

.inputRow {
  padding-bottom: 25px;
}
