@import '~/ui/assets/styles/colors.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-right: 70px;
  color: $color-white;
  background-color: $color-blue-1;
  z-index: 2;
}

.leftContainer,
.rightContainer {
  display: flex;
  align-items: center;
}

.spacer {
  width: 50px;
  transition: all 0.2s;

  &.expanded {
    width: 240px;
  }
}

.toggleBtn {
  color: $color-white !important;
}

.block {
  padding-left: 30px;
}

.inner {
  display: flex;
  align-items: center;
  color: $color-white;
  text-decoration: none;
  cursor: pointer;
}

.title {
  max-width: 300px;
  padding-left: 10px;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.patientHeader {
  background-color: $color-green-1;
}

.NotificationIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .NotifyCount {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: $color-red-3;
    border-radius: 50%;
    min-width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 2px solid $color-blue-1;
    transform-origin: 0% 0%;
  }
}
