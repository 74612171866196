@import '~/ui/assets/styles/colors.scss';

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 25px;
}

.title {
  font-weight: 500;
}

.form {
  padding-bottom: 25px;
}
