@import '~/ui/assets/styles/colors';

.phoneControl {
  width: 100%;

  input {
    width: 100% !important;
    height: 40px;
    padding: 10.5px;
    font-size: 16px;
    border: 1px solid $color-grey-2;
    border-radius: 4px;
    outline: none;

    &:hover {
      border-color: $color-black-2;
    }

    &:focus {
      border-color: $color-blue-1;
    }
  }
}

.input.error {
  border-color: $color-red-1;
}
