@import '~/ui/assets/styles/colors';

.activityTimer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  padding: 10px 15px;
  font-weight: 500;
  background-color: $color-blue-5 !important;
}

.name {
  font-size: 14px;
  text-align: center;
  color: $color-black-3;
  word-break: break-word;
}

.timer {
  padding-top: 3px;
  font-size: 26px;
  color: $color-blue-6;
}
