@import '~/ui/assets/styles/colors.scss';

.popupWrapper {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 13px;
    width: 13px;
    transform-origin: 0% 0%;
    transform: rotate(45deg);
    right: -2px;
    top: 14px;
    background: white;
    border-top: 1px solid $color-grey-1;
    border-left: 1px solid $color-grey-1;
    z-index: 1002;
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.popup {
  position: fixed;
  top: 61px;
  right: 25px;
  width: 510px;
  min-height: 100px;
  max-height: 590px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: $color-black;
  list-style-type: none;
  background-color: $color-white;
  z-index: 1001;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 1rem;
  border: 1px solid $color-grey-1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.popupBody {
  overflow-y: auto;
  flex-direction: column;
  flex: 1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-2;
    border-radius: 1rem;
  }
}

.popupHeader {
  padding: 18px 0;
  text-align: center;
  vertical-align: middle;

  .HeaderTitle {
    font-size: 20px;
  }
}

.popupBottom {
  padding: 0 !important;
  text-align: center;
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

  .Title {
    font-size: 20px;
  }
}

.Divider {
  width: 100%;
  height: 1px;
  background-color: $color-grey-2;
}
