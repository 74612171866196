.top {
  padding-bottom: 35px;
}

.btnTop {
  margin-left: 10px !important;
}

.headerForm {
  padding-bottom: 25px;
}

.unAssignCol {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
