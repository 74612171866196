.row {
  padding-bottom: 25px;
}

.title {
  display: flex;
  padding-bottom: 10px;
  font-size: 18px;

  > div {
    width: 440px;
  }
}

.content {
  display: flex;
}

.contentItem {
  padding-right: 20px;
}
