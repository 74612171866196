.container {
  display: flex;
  flex-direction: column;
}

.tableContainer {
  height: 350px;
}

.buttonContainer {
  margin-top: 10px;
  align-self: flex-end;
}
