@import '~/ui/assets/styles/colors';

.smallCard {
  display: inline-block;
  min-width: 200px;
}

.inner {
  display: flex;
  align-items: center;
  padding: 18px 20px 18px 20px;
  background-color: $color-white;
  border: 1px solid $color-grey-5;
  border-radius: 12px;
  box-shadow: 0 7px 24px -10px rgba(86, 116, 138, 0.3);
  cursor: pointer;
  transition-duration: 0.15s;

  &:hover {
    color: $color-white;
    background-color: $color-blue-1;
    box-shadow: 0 4px 14px rgba(13, 118, 177, 0.4);

    .blue,
    .green,
    .purple,
    .red {
      color: $color-white;
    }

    .iconWrapper_blue,
    .iconWrapper_green,
    .iconWrapper_purple,
    .iconWrapper_red {
      background-color: $color-blue-4;
    }
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.iconWrapper_blue {
  background-color: rgba(30, 137, 194, 0.1);
}

.iconWrapper_green {
  background-color: rgba(12, 131, 102, 0.1);
}

.iconWrapper_purple {
  background-color: rgba(175, 175, 217, 0.2);
}

.iconWrapper_red {
  background-color: rgba(214, 87, 60, 0.1);
}

.icon {
  font-size: 28px !important;
}

.content {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.amount {
  font-size: 24px;
  font-weight: 700;
}

.title {
  font-size: 16px;
}

.blue {
  color: $color-blue-1;
}

.green {
  color: $color-green-1;
}

.purple {
  color: $color-purple-1;
}

.red {
  color: $color-red-1;
}
