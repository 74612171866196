@import '~/ui/assets/styles/colors';

$container-width: 198px;
$icon-width: 36px;
$status-icon-width: 30px;
$icon-margin: 4px;

.container {
  height: 40px;
  width: $container-width;
  display: flex;
  align-items: center;
  border: 1px solid $color-grey-9;
  border-radius: 5px;
  background: white;
  padding-right: $icon-margin;
}

.isHighlighted {
  border-color: $color-blue-1;
  background: $color-blue-2;
}

.icon {
  width: $icon-width;
  display: flex;
  justify-content: center;
}

.statusIcon {
  width: $status-icon-width;
  margin-left: $icon-margin;
  display: flex;
  justify-content: center;
}

.info {
  width: ($container-width - $icon-width - $status-icon-width - $icon-margin * 2 - 2px);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.measurements {
  display: flex;
  justify-content: space-between;
  height: 18px;
}

.value {
  font-size: 14px;
  font-weight: 700;
}

.unit {
  font-size: 12px;
  font-weight: 500;
  margin-left: 3px;
}

.date {
  width: ($container-width - $icon-width - $status-icon-width - $icon-margin * 2);
  font-size: 10px;
  text-align-last: justify;
}

.level_1 {
  color: $color-blue-4;
}

.level_2 {
  color: $color-yellow-1;
}

.level_3 {
  color: $color-red-3;
}
