.modal {
  width: 100%;
}

.container {
  padding: 20px 0 20px;
}

.btnWrapper {
  padding: 20px 0 15px;
}
