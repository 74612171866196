@import '~/ui/assets/styles/colors';

.datePickerControl {
  width: 100%;
  text-align: left;
}

.label {
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 0 4px !important;
  font-size: 12px !important;
  background: $color-white;
  z-index: 1;
}

.inputWrapper {
  position: relative;
}

.datePicker {
  width: 100%;
  height: 40px;
  padding: 10.5px 40px 10.5px 10.5px;
  font-size: 16px;
  border: 1px solid $color-grey-2;
  border-radius: 4px;
  outline: none;

  &:hover {
    border-color: $color-black-2;
  }

  &:focus {
    border-color: $color-blue-1;
  }
}

.icon {
  position: absolute !important;
  top: -4px;
  right: 0;
  color: $color-blue-1 !important;
}
