.container {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  margin-top: 10px;
  align-self: flex-end;
}

.tableContainer {
  max-height: 350px;
}

.button {
  margin-top: 10px;
}

.divider {
  height: 25px;
  width: 1px;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}
