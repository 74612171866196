@import '~/ui/assets/styles/colors';

.link {
  color: $color-blue-1;

  &:hover {
    color: $color-blue-4;
  }
}

.cell {
  word-break: break-word;
}

.latestReadingWidgets {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
