.container {
  padding-top: 20px;
}

.title {
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.cell {
  word-break: break-word;
}
