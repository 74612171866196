@import '~/ui/assets/styles/colors';

.cell {
  word-break: break-word;
}

.info {
  display: flex;
  align-items: center;
}

.checkbox svg {
  color: $color-blue-1;
}
