@import '~/ui/assets/styles/colors.scss';

.dropzone {
  width: 100%;
  height: 100%;
  align-items: center;
  color: $color-grey-1;
}

.dropzoneTarget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  border: 1px dashed $color-grey-1;
  cursor: pointer;
}

.draggingOverTargetClassName {
  background-color: $color-blue-2;
}

.icon {
  font-size: 32px;
}

.hidden {
  display: none;
}
