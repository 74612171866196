@import '~/ui/assets/styles/colors';

.headerName {
  font-size: 20px;
  font-weight: 400;
}

.icon {
  margin-right: 5px;
  color: $color-blue-1;
}

.text {
  text-transform: capitalize;
  color: $color-blue-1;
}
