@import '~/ui/assets/styles/colors';

.container {
  padding: 4px 8px;
  border: 1px solid $color-grey-9;
  border-radius: 5px;
}

.text {
  padding-left: 8px;
}
