.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  &.expanded {
    height: 125px;

    .logo {
      height: 85px;
      width: 140px;
    }
  }
}

.logo {
  height: 30px;
  width: 40px;
}
