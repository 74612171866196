$color-white: white;
$color-white-2: #fdfdfd;

$color-black: black;
$color-black-1: #131f25;
$color-black-2: #1e1f21;
$color-black-3: #143062;

$color-grey: grey;
$color-grey-1: #c9d2e0;
$color-grey-2: #c4c4c4;
$color-grey-3: #f7f9fa;
$color-grey-4: #5b7d8e;
$color-grey-5: #dae2ee;
$color-grey-6: #e5e5e5;
$color-grey-7: #90a2b4;
$color-grey-8: #253858;
$color-grey-9: #c6d0df;
$color-grey-10: #a4b4cc;
$color-grey-11: #f6f6f6;
$color-grey-12: #9d9d9d;
$color-grey-13: #89989f;
$color-grey-14: #676767;
$color-grey-15: #e3e3e3;
$color-grey-16: #e6e6e6;

$color-green: green;
$color-green-1: #33ad90;
$color-green-2: #e7f3f0;
$color-green-3: #f7fdfb;
$color-green-4: #1EB564;
$color-green-5: #11AF22;

$color-yellow: yellow;
$color-yellow-1: #e4bf3a;
$color-yellow-2: #ffb700;
$color-yellow-3: #fbecbd;

$color-red: red;
$color-red-1: #f44336;
$color-red-2: #ff6f69;
$color-red-3: #c54326;
$color-red-4: #ea8b76;
$color-red-5: #f45760;

$color-blue: blue;
$color-blue-1: #1e89c2;
$color-blue-2: #e3f2fd;
$color-blue-3: #f7fcff;
$color-blue-4: #409bca;
$color-blue-5: #e9f3f9;
$color-blue-6: #3997c9;
$color-blue-7: #ceeaff;
$color-blue-8: #0f91d2;
$color-blue-9: #F3F9FD;

$color-orange: orange;
$color-orange-1: #e76c51;

$color-purple-1: #9797E3;
