.addNotificationRule {
  padding-top: 15px;
}

.title {
  padding-bottom: 10px;
}

.btnWrapper {
  padding-top: 5px;
}

.btnSave {
  margin-left: 8px !important;
}
