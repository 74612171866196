.medication {
  margin-top: 30px;
  padding: 30px;
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
}
