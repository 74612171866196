.option {
  display: flex !important;
  align-items: center;
  height: 100%;
}

.label {
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
