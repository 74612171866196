@import '~/ui/assets/styles/colors';

.datePicker {
  min-width: 180px;
  padding: 4px;
  background: white;
  border: 1px solid $color-blue-1;
  border-radius: 4px;
  color: $color-blue-1;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  outline: none;
}