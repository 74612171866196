@import '~/ui/assets/styles/colors';

.container {
  margin-bottom: 20px;
}

.total {
  height: 40px;
  padding: 0 25px !important;
  font-size: 20px;
  font-weight: 700;
  border-radius: 6px;
}

.blue {
  color: $color-blue-1;
  background-color: rgba(30, 137, 194, 0.1);
}

.green {
  color: $color-green-1;
  background-color: rgba(12, 131, 102, 0.1);
}

.purple {
  color: $color-purple-1;
  background-color: rgba(175, 175, 217, 0.2);
}

.red {
  color: $color-red-1;
  background-color: rgba(214, 87, 60, 0.1);
}
