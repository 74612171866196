.modal {
  width: 100%;
}

.form {
  padding: 80px 20px 70px;
}

.btnWrapper {
  padding-top: 40px;
  text-align: right;
}

.spacer {
  height: 20px;
}
