.modal {
  width: 100%;
  min-height: 350px;

  .buttons {
    margin-top: 30px;
  }
}

.dragListContainer {
  position: relative;

  .dragPlaceholderContainer {
    position: absolute;
    width: 100%;
    margin: 0;

    .dragPlaceholderItem {
      height: 58px;
      border: 2px #aabdd9 dashed;
      border-radius: 10px;
      background: #aabdd933;
    }
  }
}

.dragItem {
  z-index: 0;

  .dragContainer {
    height: 58px;
    border: 1px #e4e4e4 solid;
    border-radius: 10px;
    background: #fff;

    &.isDragging {
      border: 2px #aabdd9 solid;
      box-shadow: 0 20px 40px 0 #00000040;
    }

    .dragHandler {
      height: 30px;
      width: 20px;
      margin: 0 20px;
      background: radial-gradient(#000 0, #000 2px, transparent 2px) repeat;
      background-size: 10px 10px;
      cursor: move;
    }
  }
}

.columnLabel {
  font-size: 16px;
}
