@import '~/ui/assets/styles/colors';

.smallCard {
  display: inline-block;
  min-width: 200px;
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  background-color: $color-white;
  border: 1px solid $color-grey-5;
  border-radius: 12px;
  box-shadow: 0 7px 24px -10px rgba(86, 116, 138, 0.3);
  cursor: pointer;
  transition-duration: 0.15s;

  &:hover {
    color: $color-white;
    background-color: $color-blue-1;
    box-shadow: 0 4px 14px rgba(13, 118, 177, 0.4);

    .blue,
    .iconSmall,
    .divider {
      color: $color-white;
    }

    .iconWrapper_blue {
      background-color: $color-blue-4;
    }
  }
}

.top {
  display: flex;
  padding: 18px 10px 10px;

  &:last-child {
    padding-bottom: 18px;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.iconWrapper_blue {
  background-color: rgba(30, 137, 194, 0.1);
}

.icon {
  font-size: 28px !important;
}

.content {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.amount {
  font-size: 24px;
  font-weight: 700;
}

.title {
  font-size: 16px;
}

.issuedWrapper,
.readyWrapper {
  font-size: 13px;
  text-align: center;
}

.issuedWrapper {
  padding-right: 20px;
}

.blue {
  color: $color-blue-1;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $color-grey-5;
}

.verticalDivider {
  width: 1px;
  height: 52px;
  margin: 0 50px;
  background-color: $color-grey-5;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 18px;
  font-size: 13px;
  text-align: center;
}

.iconSmall {
  font-size: 22px;
}

.iconIssued {
  color: $color-yellow-1;
}

.iconReady,
.iconAvailable {
  color: $color-green-1;
}

.iconReturning {
  color: $color-red-4;
}

.iconReturned {
  color: $color-blue-1;
}

.iconLost {
  color: $color-red-5;
}
