@import '~/ui/assets/styles/colors.scss';

.patientProfile {
  padding: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.title {
  font-size: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $color-grey-2;
}

.saveBtnWrapper {
  text-align: right;
  padding-top: 50px;
}
