.preview {
  padding: 12px 15px;
}

.previewInner {
  min-height: 100px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
