@import '~/ui/assets/styles/colors';

.deviceStatus {
  display: inline-block;
}

.indicators {
  display: flex;
}

.indicator {
  flex: 0 0 75px;
  display: flex;
  align-items: center;
}

.date {
  padding-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
