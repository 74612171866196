@import '~/ui/assets/styles/colors.scss';

.itemContainer {
  min-height: 50px;
  margin-bottom: 10px;
  padding: 3px 10px 10px 15px;
  font-size: 0.9em;
  border-radius: 5px;
  border: 1px solid $color-grey-10;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 35px;
}

.author {
  padding-left: 35px;
}

.bold {
  color: $color-grey-8;
  font-weight: bold;
}

.iconWrapper {
  margin-right: 5px !important;
  padding: 2px !important;
}

.iconButton {
  padding: 5px !important;
}

.icon {
  font-size: 20px !important;

  &:hover {
    color: $color-blue-1;
  }
}

.isTelephoneConversation {
  background: $color-blue-2;
  border-color: $color-blue-1;
}
