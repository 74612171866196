.form {
  padding-top: 10px;
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.heightSpacer {
  height: 15px;
}

.spacer {
  width: 10px;
}
