.modal {
  width: 100%;
  min-height: 400px;
}

.container {
  padding: 20px 10px;
}

.subTitle {
  text-align: center;
}
