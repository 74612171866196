@import '~/ui/assets/styles/colors';

.lockIcon {
  width: 100px;
  height: 100px;
}

.row {
  padding-top: 30px;
}

.btn {
  width: 100%;
}

.serverError {
  padding-top: 10px;
  color: $color-red;
}

.resendCode {
  color: $color-blue;
  text-decoration: underline;
  cursor: pointer;
  text-transform: none;
}
