@import '~/ui/assets/styles/colors';

.container {
  margin: 25px 0;
  border-radius: 15px 15px 0 0;
  background-color: $color-white;
  padding-bottom: 15px;
  border: 1px solid $color-grey-6;
}

.formTopHeader {
  margin-top: 0;
  padding: 5px 20px;
  width: 100%;
  background-color: $color-white-2;
  border-radius: 15px 15px 0 0;
}

.divider {
  height: 25px;
  width: 1px;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.selectInput {
  width: 50%;
  margin-bottom: 15px;
}

.button {
  align-self: flex-end;
}
