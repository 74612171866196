@import '~/ui/assets/styles/colors';

.container {
  height: 100%;
  border: 1px solid $color-grey-7;
  border-radius: 4px;
  overflow: hidden;
}

.header {
  padding: 10px;
  color: $color-white;
  background-color: $color-grey-7;
}

.inner {
  position: relative;
  min-height: 400px;
}

.noData {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
}
