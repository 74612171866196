@import '~/ui/assets/styles/colors';

.accessDenied {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 20px;
  font-size: 36px;
  background-color: $color-grey-11;
}

.image {
  width: 300px;
}

.title {
  padding: 10px 0 30px;
  color: $color-blue-1;
}

.link {
  text-decoration: none;
}
