@import '~/ui/assets/styles/colors.scss';

.menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.spacer {
  width: 20px;
}

.link {
  display: flex;
  align-items: center;
  padding: 13px 0;
  color: $color-black-1;
  text-decoration: none;

  &:hover {
    background-color: $color-blue-3;
  }
}

.active {
  color: $color-blue-1;
  background-color: $color-blue-2;
  border-right: 3px solid $color-blue-1;

  .icon {
    color: $color-blue-1;
  }

  &:hover {
    background-color: $color-blue-2;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.expanded {
    width: auto;
  }
}

.icon {
  color: $color-grey-4;
}

.title {
  padding-left: 10px;
}

.patientMenu {
  .link {
    &:hover {
      background-color: $color-green-3;
    }
  }

  .active {
    background-color: $color-green-2;
    border-right: 3px solid $color-green-1;

    .icon {
      color: $color-green-1;
    }

    &:hover {
      background-color: $color-green-2;
    }
  }
}
