.signal {
  display: flex;
  align-items: center;
}

.title {
  padding-left: 1px;
  font-size: 13px;
  font-weight: 500;
}
