@import '~/ui/assets/styles/colors';

.link {
  color: $color-blue-1;

  &:hover {
    color: $color-blue-4;
  }
}

.icon {
  padding: 5px !important;
}

.infoIcon {
  color: $color-blue-1 !important;
}

.centerCell {
  text-align: center;
}

.cell {
  word-break: break-word;
}
