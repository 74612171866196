.insurance {
  margin-top: 30px;
  padding: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.title {
  font-size: 20px;
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
}
