@import '~/ui/assets/styles/colors';

.tabs {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.tabWrapper {
  align-items: flex-start !important;
  padding-left: 10px;
}

.tab {
  max-width: 100% !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: $color-black-1 !important;
  background-color: none !important;
  border-bottom: 1px solid $color-blue-5 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  opacity: 1 !important;

  &:hover {
    background-color: $color-blue-3;
  }
}

.tabSelected {
  color: $color-blue-1 !important;
  background-color: $color-blue-2 !important;
  border-right: 3px solid $color-blue-1 !important;
}
