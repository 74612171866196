.inputContainer {
  margin-bottom: 15px;
}

.divider {
  height: 25px;
  width: 1px;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}
