@import '~/ui/assets/styles/colors.scss';

.container {
  padding: 0 10px;
  border: 1px solid $color-grey-15;
  border-radius: 4px;
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cancel {
  color: $color-grey-16 !important;
}

.error {
  color: $color-red-3;
}
