@import 'fonts.scss';
@import 'colors.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: $color-black-1;
}

.react-datepicker-popper {
  z-index: 2;
}

// TODO: Kludge.
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #fff;
  color: #000;

  &:hover {
    background-color: #f0f0f0;
  }

  &.react-datepicker__month--in-range {
    background-color: #216ba5;
    color: #fff;
  }
}


.react-tel-input {
  .special-label {
    left: 10px;
    color: rgba(0, 0, 0, 0.54);
  }
}
