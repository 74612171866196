.name {
  min-width: 150px;
}

.cell {
  word-break: break-word;
}

.icon {
  padding: 5px !important;
}
