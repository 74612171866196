@import '~/ui/assets/styles/colors.scss';

.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $color-grey-2;
}

.title {
  padding-bottom: 20px;
  font-size: 18px;
  color: $color-blue-1;
}

.spacer {
  display: inline-block;
  width: 10px;
}

.rightBtnCol {
  display: flex;
  justify-content: flex-end;
}
