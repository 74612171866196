@import '~/ui/assets/styles/colors.scss';

.quickNotes {
  position: relative;
  display: flex;
  margin-right: 8px;
}

.icon {
  color: $color-yellow-2;
  cursor: pointer;
}
