@import '~/ui/assets/styles/colors';

.popper {
  z-index: 1;
}

.actions {
  background-color: white;
}

.btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-3;
  }

  svg {
    color: $color-blue-1;
  }
}

.btnDisabled {
  svg {
    color: $color-grey-2;
  }
}

.title {
  padding-left: 5px;
  font-size: 14px;
}
