.container {
  padding: 0;
}

.title {
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.icon {
  width: 25px;
}
