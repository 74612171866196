@import '~/ui/assets/styles/colors';

.container {
  padding: 40px 0;
}

.icon {
  font-size: 100px !important;
}

.title {
  font-size: 16px;
  font-weight: 400;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: $color-grey-13;
}
