.modal {
  width: 100%;
  min-height: 350px;
}

.inner {
  padding: 10px 0;
}

.heightSpacer {
  height: 20px;
}
