.saveBtnWrapper {
  text-align: right;
  padding: 70px 0 15px;
}

.icon {
  padding: 5px !important;
}

.controlButtons {
  display: flex;
  align-items: center;
}

.titleContainer {
  height: 80px;
}

.title {
  font-size: 16px;
  font-weight: 500;
}
