@import '~/ui/assets/styles/colors.scss';

.input[type=file] {
  display: none;
}

.dropContainer {
  background: $color-blue-9;
  border: 2px dashed $color-blue-8;
  border-radius: 4px;

  .small {
    height: 450px;
  }
}

.dropContainerContent {
  height: 550px;
}

.callToAction {
  margin-top: 40px !important;
  font-size: 16px;
  font-weight: 700;
}

.browse {
  color: $color-blue-8;
  text-decoration: underline;
  cursor: pointer;
}

.limitations {
  margin-top: 20px !important;
  color: $color-grey-14;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.uploading {
  color: $color-grey-14;
  font-size: 16px;
  font-weight: 700;
}
