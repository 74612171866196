@import '~/ui/assets/styles/colors';

.container {
  padding: 20px;
}

.green {
  color: $color-green-1;
}

.red {
  color: $color-red-1;
}
