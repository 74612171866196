@import '~/ui/assets/styles/colors';

.infoBlock {
  display: flex;
  align-items: center;
}

.maxNotificationLevel {
  display: flex;
  align-items: inherit;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: $color-grey-10;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  border: 2px solid $color-grey-10;
  border-radius: 50%;
}

.Level1 {
  color: $color-blue-4;
  border-color: $color-blue-4;
}

.Level2 {
  color: $color-yellow-1;
  border-color: $color-yellow-1;
}

.Level3 {
  color: $color-red-3;
  border-color: $color-red-3;
}

.link {
  color: $color-blue-1;

  &:hover {
    color: $color-blue-4;
  }
}

.icon {
  padding: 5px !important;
}

.infoIcon {
  color: $color-blue-1 !important;
}

.centerCell {
  text-align: center;
}

.cell {
  word-break: break-word;
}

.latestReadingPatientsWidgets {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
