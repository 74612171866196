@import '~/ui/assets/styles/colors';

.wrapper {
  padding: 0 0 20px;
}

.panel {
  padding: 20px 30px;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 0;
  background-color: $color-grey-3;
  border: 1px solid $color-grey-11;
}

.day {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
}

.dayColor {
  width: 20px;
  height: 10px;
  margin-right: 5px;
}
