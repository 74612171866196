.modal {
  width: 100%;
  min-height: 350px;
}

.container {
  padding: 20px 0 100px;
}

.btnWrapper {
  padding: 20px 0 15px;
}

.counterContainer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 3;
}
