@import '~/ui/assets/styles/colors.scss';

.Item {
  display: flex;
  align-items: center;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: $color-grey-11;
  }

  &.IsSelecting {
    padding-left: 0;
  }
}

.Notification {
  display: flex;
  flex-direction: column;
  flex: 1;

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-blue-1;

    .HeaderTitle {
      display: flex;
      align-items: center;
    }
  }

  .Body {
    margin-top: 7px;
  }

  .Bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    visibility: hidden;

    &.IsRead {
      visibility: visible;
    }

    .BottomRead {
      font-size: 11px;
      margin-right: 3px;
    }

    .BottomReadIcon {
      font-size: 16px;
    }
  }
}

.IsRead {
  color: $color-grey-4 !important;
}

.IsReadCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $color-orange-1;
  display: inline-block;
  margin: 0 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 30px;
}
