@import '~/ui/assets/styles/colors.scss';

.container {
  margin: 25px 0 15px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  background-color: $color-grey-5;
  border-radius: 5px;
}
