@import '~/ui/assets/styles/colors';

.container {
  border-radius: 15px 15px 0 0;
  background-color: $color-white;
  padding-bottom: 15px;
  border: 1px solid $color-grey-6;
}

.marginTop {
  margin-top: 10px;
}

.tableContainer {
  padding: 0 15px;
}
