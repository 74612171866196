@import '~/ui/assets/styles/colors.scss';

.addUser {
  padding: 15px 0;
}

.title {
  padding-bottom: 8px;
  color: $color-blue-1;
}

.generalInfoTitle {
  padding-bottom: 18px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $color-grey-2;
}

.notificationsDivider {
  margin-top: 5px;
}

.saveBtnWrapper {
  text-align: right;
  padding-top: 50px;
}
