@import '~/ui/assets/styles/colors';

.tab {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: $color-grey-3;
  border-radius: 4px;
}
