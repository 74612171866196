@import '~/ui/assets/styles/colors';

.container {
  border-radius: 15px 15px 0 0;
  background-color: $color-white;
  padding-bottom: 15px;
  border: 1px solid $color-grey-6;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .actions {
    display: flex;
    align-items: center;
  }
}

.title {
  font-weight: 500;
}

.tableContainer {
  padding: 0 15px;
}

.iconWrapper {
  margin-right: 5px !important;
  padding: 2px !important;
}

.iconButton {
  padding: 5px !important;
}

.icon {
  font-size: 20px !important;

  &:hover {
    color: $color-blue-1;
  }
}

.smokerControl {
  flex-direction: row !important;
  align-items: center;
  margin-right: 24px;
}

.smokerLabel {
  font-weight: 500;
  margin-right: 24px;
}
