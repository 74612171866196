@import '../Reading.module.scss';

.info {
  width: ($container-width - $icon-width - $icon-margin - 2);
}

.measurements {
  justify-content: flex-start;

  .measurement {
    display: flex;
    align-items: center;
    width: 54px;
  }

  .value {
    font-size: 12px;
    padding: 0 1px;
    line-height: normal;
  }
}
