@import '~/ui/assets/styles/colors.scss';

.form {
  padding: 10px;
}

.textarea {
  & > div {
    padding: 10px;
  }

  textarea {
    min-width: 258px;
    min-height: 160px;
    font-size: 14px;
    resize: auto;
  }
}
