@import '~/ui/assets/styles/colors.scss';

.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $color-grey-2;
}

.title {
  padding-bottom: 12px;
  color: $color-blue-1;
}
