@import '~/ui/assets/styles/colors.scss';

.wrapper {
  margin-bottom: 25px;
}

.topWrapper {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  height: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #efefef;
}

.title {
  margin-left: 5px;
}

.wrapperContent {
  background-color: $color-white;
  padding: 15px 10px;
}
