@import '~/ui/assets/styles/colors';

.programBtnWrapper {
  display: inline-block;
  padding-right: 10px;
}

.cell {
  word-break: break-word;
}

.removeCell {
  padding-right: 25px !important;
}

.icon {
  padding: 5px !important;
}
