.header {
  height: 36px;
  margin-bottom: 20px;

  .title {
    font-size: 20px;
    font-weight: 400;
  }

  .addButton {
    width: 132px;
  }
}

.container {
  padding: 30px;
}
