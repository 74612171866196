.modal {
  min-width: 600px;
}

.inner {
  padding-bottom: 20px;
}

.description {
  padding: 30px 0;
  font-size: 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
}

.btnCancel {
  margin-right: 10px !important;
}
