@import '~/ui/assets/styles/colors';

.notificationRules {
  padding-top: 15px;
  border-top: 1px solid $color-grey-1;
  margin-bottom: 20px;
}

.title {
  padding-bottom: 10px;
  font-size: 18px;
}

.hint {
  padding-left: 5px;
  font-size: 14px;
}
