@import '~/ui/assets/styles/colors';

.logo {
  max-width: 330px;
  max-height: 200px;
}

.row {
  padding-top: 30px;
}

.btn {
  width: 100%;
}

.serverError {
  padding-top: 10px;
  color: $color-red;
}
