@import '~/ui/assets/styles/colors.scss';

.modal {
  min-width: 600px;
  min-height: 340px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $color-grey-2;
}

.title {
  padding-bottom: 12px;
  color: $color-blue-1;
}

.btnWrapper {
  text-align: right;
  padding-top: 50px;
}
