@import '~/ui/assets/styles/colors.scss';

.label {
  padding-bottom: 15px;
  font-size: 16px !important;
  color: $color-grey-8;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    width: 50%;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.controlLabelRoot {
  align-items: flex-start !important;
}

.controlLabel {
  padding-top: 10px !important;
  font-size: 14px !important;
}
