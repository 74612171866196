.modal {
  width: 100%;
}

.inner {
  padding: 10px 0;
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.widthSpacer {
  width: 20px;
}
