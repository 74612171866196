@import '~/ui/assets/styles/colors';

.contentContainer {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &.full {
    display: block;
  }
}

.readMoreLessContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;

  &.full {
    position: initial;
    display: inline;
    padding-left: 4px;
  }
}

.readMoreLessToggle {
  color: $color-blue-1;
  text-decoration: underline;
  cursor: pointer;
}

.blueBackground {
  background: $color-blue-2;
}
