@import '~/ui/assets/styles/colors.scss';

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  background-color: $color-white;
  transition: all 0.2s;
  z-index: 3;

  &.expanded {
    width: 240px;
  }
}

.inner {
  height: 100%;
  border-right: 1px solid $color-grey-1;
}
