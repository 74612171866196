@import '~/ui/assets/styles/colors';

.title {
  padding-bottom: 5px;
  font-weight: 500;
  color: $color-grey-8;
  word-break: break-word;
}

.timezone {
  font-size: 13px;
  color: $color-grey;
}

.removeCell {
  padding-right: 25px !important;
}

.icon {
  padding: 5px !important;
}
