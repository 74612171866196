@import '~/ui/assets/styles/colors.scss';

.container {
  padding: 0 10px;
  border: 1px solid $color-green-5;
  border-radius: 4px;
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
