@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: normal;
  font-weight: 100;
  src:
    url(../fonts/Roboto-Thin.woff2) format('woff2'),
    url(../fonts/Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: italic;
  font-weight: 100;
  src:
    url(../fonts/Roboto-ThinItalic.woff2) format('woff2'),
    url(../fonts/Roboto-ThinItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
  src:
    url(../fonts/Roboto-Light.woff2) format('woff2'),
    url(../fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: italic;
  font-weight: 300;
  src:
    url(../fonts/Roboto-LightItalic.woff2) format('woff2'),
    url(../fonts/Roboto-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src:
    url(../fonts/Roboto-Regular.woff2) format('woff2'),
    url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: italic;
  font-weight: 400;
  src:
    url(../fonts/Roboto-Italic.woff2) format('woff2'),
    url(../fonts/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: normal;
  font-weight: 500;
  src:
    url(../fonts/Roboto-Medium.woff2) format('woff2'),
    url(../fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: italic;
  font-weight: 500;
  src:
    url(../fonts/Roboto-MediumItalic.woff2) format('woff2'),
    url(../fonts/Roboto-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src:
    url(../fonts/Roboto-Bold.woff2) format('woff2'),
    url(../fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: italic;
  font-weight: 700;
  src:
    url(../fonts/Roboto-BoldItalic.woff2) format('woff2'),
    url(../fonts/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: normal;
  font-weight: 900;
  src:
    url(../fonts/Roboto-Black.woff2) format('woff2'),
    url(../fonts/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-display: fallback;
  font-style: italic;
  font-weight: 900;
  src:
    url(../fonts/Roboto-BlackItalic.woff2) format('woff2'),
    url(../fonts/Roboto-BlackItalic.ttf) format('truetype');
}
