@import '~/ui/assets/styles/colors.scss';

.layout {
  display: flex;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 50px;
  background-color: $color-grey-3;
  transition: all 0.2s;

  &.expanded {
    padding-left: 240px;
  }
}

.content {
  flex-grow: 1;
  padding: 75px 25px 25px;
}

.counterContainer {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1301;
}
