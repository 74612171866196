@import '~/ui/assets/styles/colors.scss';

.table {
  table-layout: fixed;
}

.editButton {
  color: white !important;
}

.deleteButton {
  color: $color-red-3 !important;
}

.readingsContainer {
  overflow: auto;
}