@import '~/ui/assets/styles/colors.scss';

.listItem {
  list-style: none;
}

.noOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  font-size: 15px;
  color: $color-grey-12;
}
