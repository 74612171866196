@import '~/ui/assets/styles/colors.scss';

.patientEnrollment {
  padding: 15px 0;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $color-grey-2;
}

.title {
  padding-bottom: 12px;
  color: $color-blue-1;
}

.saveBtnWrapper {
  text-align: right;
  padding-top: 50px;
}

.MobileUserInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
