@import '~/ui/assets/styles/colors.scss';

.patientPanel {
  display: flex;
  align-items: center;
  font-weight: 300;
}

.title {
  font-weight: 500;
}

.divider {
  width: 0;
  height: 14px;
  margin: 0 10px;
  border-right: 1px solid $color-white;
}
