@import '~/ui/assets/styles/colors';

.link {
  color: $color-blue-1;
  text-decoration: none;

  &:hover {
    color: $color-blue-4;
  }
}

.cell {
  word-break: break-word;
}
