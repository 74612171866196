@import '~/ui/assets/styles/colors.scss';

.popup {
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 1000;
}

.container {
  position: relative;
  min-width: 300px;
  min-height: 100px;
  margin: 0;
  font-size: 14px;
  color: $color-black;
  background-color: $color-yellow-3;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border: 1px solid $color-yellow-2;
  border-radius: 0.25rem;
  z-index: 1;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 0;
}
