.divider {
  height: 25px;
  width: 1px;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}

.tableContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.cell {
  word-break: break-word;
}
