.cell {
  word-break: break-word;
}

.event {
  display: flex;
  align-items: center;
}

.eventName {
  padding: 5px 0 0 12px;
}
