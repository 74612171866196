@import '~/ui/assets/styles/colors';

.templatesContainer {
  height: 384px;
  overflow-y: auto;
}

.templateContainer {
  height: 48px;
  padding: 0 12px;
  border-left: 4px solid transparent;
  cursor: pointer;

  &:hover {
    background: $color-blue-2;
  }

  &.isSelected {
    background: $color-blue-2;
    border-color: $color-blue-1;
  }
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
