@import '~/ui/assets/styles/colors';

.container {
  border-radius: 15px 15px 0 0;
  background-color: $color-white;
  padding-bottom: 15px;
  border: 1px solid $color-grey-6;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.title {
  font-weight: 500;
}

.tableContainer {
  padding: 0 15px;
}

.iconWrapper {
  margin-right: 5px !important;
  padding: 2px !important;
}
